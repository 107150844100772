//colors 
$darkBlue: hsl(233, 26%, 24%);
$LimeGreen: hsl(136, 65%, 51%);
$BrightCyan: hsl(192, 70%, 51%);

$GrayishBlue: hsl(233, 8%, 62%);
$LightGrayishBlue: hsl(220, 16%, 96%);
$VeryLightGray: hsl(0, 0%, 98%);
$White: hsl(0, 0%, 100%);

//re-used fonts
$med-font: 1.125rem; //18px
$small-font: 0.875rem; //14px
$l-font: 1.25rem; //20px
$xl-font: 3.25rem; //52px